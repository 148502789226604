<template>
    <div>
        <h4 v-if="request">
            Code de rappel: <b>{{ request.id }}</b>
        </h4>
        <h4 v-else>
            Créer une requête
        </h4>

        <div v-if="step_one">
            <div class="card mt-3">
                <div class="card-header">
                    <h5>Étape 1</h5>
                </div>
                <div class="card-body">

                    <div class="row">
                        <div class="col">
                            <label for="name">Nom</label>
                            <input class="form-control" type="text" name="name" v-model="name" id="name" />
                        </div>
                        <div class="col">
                            <label for="division_id">Division</label>
                            <select class="form-control" v-model="division_id" name="division_id" id="division_id">
                                <option :value="division.id" v-for="division in divisions" :key="division.id">
                                    {{ division.label }}
                                </option>
                            </select>
                        </div>
                        <div class="col">
                        <label for="sorting">Choisir le modèle de tri</label>
                            <select class="form-control" v-model="sorting" name="sorting" id="sorting">
                                <option :value="id" v-for="(model, id) in sort_models" :key="id">{{ model }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-6 form-group">
                            <label for="start_date">Date de début</label> <input class="form-control" type="date" name="start_date" v-model="start_date" id="start_date">
                        </div>

                        <div class="col-md-6 form-group">
                            <label for="end_date">Date de fin</label> <input class="form-control" type="date" name="end_date" v-model="end_date" id="end_date">
                        </div>
                    </div>

                    <div class="mt-3">
                        <h6>
                            <i class="bx bx-info-circle mr-2" aria-hidden="true"></i>
                            Veuillez choisir tous les postes associées
                        </h6>
                    </div>

                    <div class="row row-cols-3 d-flex m-0 mt-3">
                        <div v-for="job in jobs" :key="job.id" >
                            <label
                                style="height:100%"
                                :for="jobs.id"
                                role='button'
                                :class="jobs_claimed[job.id] !== undefined && jobs_claimed[job.id] == true ? 'bg-dark text-white col p-3 mb-0 mr-2 border text-left' : 'col p-3 mb-0 border mr-2 text-left'">
                                <input class="form-check-input ml-0" type="checkbox" :name="jobs[job.id]" v-model="jobs_claimed[job.id]" :id="job.id">
                                <small class="ml-4">
                                    {{ job.label }}
                                </small>
                            </label>
                        </div>
                    </div>

                    <div class="mt-3">
                        <label for="note"> Détails ou notes supplémentaires <span class="text-muted">(Falcultatif)</span> </label>
                        <textarea class="form-control custom-textarea" v-model="note" name="note" id="note"></textarea>
                    </div>

                    <div class="mt-3 alert alert-danger alert-block" v-if="step_one_errors.length > 0">
                        <ul class="mb-0">
                            <li v-for=" error in step_one_errors" :key="error"> {{ error }}</li>
                        </ul>
                    </div>

                    <button class="btn btn-dark mt-3 px-4" @click="getWorkers()">
                        Obtenir la liste
                    </button>

                </div>
            </div>

        </div>

        <div v-else>
            <div class="card mt-3">
                <div class="card-header">
                    <div class="d-flex justify-content-between">
                        <h5>
                            Étape 2
                        </h5>
                        <button class="btn btn-outline-primary mr-2 px-4" @click="refreshWorkers()">
                            Obtenir une nouvelle liste
                        </button>
                    </div>
                </div>
                <div class="card-body">

                    <div class="row">
                        <div class="col w-100">
                            <p><strong>Division :</strong> {{ getLabelById('divisions', division_id) }}</p>
                        </div>
                        <div class="col w-100">
                            <p><i class="bx bx-calendar mr-2" aria-hidden="true"></i> <strong>Date de début :</strong> {{ start_date }}</p>
                        </div>
                        <div class="col w-100">
                            <p><i class="bx bx-calendar mr-2" aria-hidden="true"></i> <strong>Date de fin :</strong> {{ end_date }}</p>
                        </div>
                    </div>

                    <hr>

                    <h6>Postes sélectionnés :</h6>

                    <div class="row row-cols-1 row-cols-md-4 m-0">
                        <div class="col p-3 border" v-for="(job, index) in  jobs_claimed" :key="index" v-if="job">
                            {{ getLabelById('jobs', index) }}
                        </div>
                    </div>

                    <hr>

                    <div class="mt-3">
                        <h6>Employés pouvant réaliser la requête</h6>
                        <table class="table  mt-4">
                            <thead>
                                <tr>
                                    <th scope="col">Nom, prénom</th>
                                    <th scope="col">Emploi</th>
                                    <th scope="col">Temps supplémentaire<br /> (Temps Refusé)</th>
                                    <th scope="col">Ancienneté</th>
                                    <th scope="col" v-if="request">Statut</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody  v-for="(worker, index) in workers" :key="index" >
                                <tr :class="worker.ofDivision == true ? 'table-success' : 'table-light'">
                                    <td>
                                        {{ worker.last_name + ' ' + worker.first_name }}
                                    </td>
                                    <td>
                                        <span v-for="(job, index) in worker.selectedJobs" :key="index">
                                            <!-- {{job.name.label}} -->
                                            <span v-if="job.pivot.is_principal" class="font-weight-bold">
                                                {{job.label}} (Principal)
                                            </span>
                                            <span v-else>
                                                {{job.label}}
                                            </span>
                                            <br/>

                                        </span>
                                    </td>
                                    <td>
                                        <strong>{{ worker.stats.extra_time_executed !== null ? parseFloat(worker.stats.extra_time_executed) : '0.00'  }} </strong>
                                        <br/>
                                        ({{ worker.stats.extra_time_refused !== null ? parseFloat(worker.stats.extra_time_refused) : '0.00' }})
                                    </td>
                                    <td>
                                        Date d'embauche : {{ worker.hiring_date }}
                                    </td>
                                    <td v-if="request && worker.calls">
                                        {{ worker.calls.textStatus + ' - ' + worker.calls.hour_call }}
                                    </td>
                                    <td>
                                        <div class="btn btn-outline-dark mr-2 mb-2" @click="openWorker(index)"  v-if="!worker.contact.show">
                                            Contacter
                                            <i class="fas fa-angle-right ml-2"></i>
                                        </div>
                                        <div class="btn btn-outline-dark mr-2 mb-2" v-else>
                                            Contacter
                                            <i class="fas fa-angle-down ml-2"></i>
                                        </div>
                                    </td>
                                </tr>
                                    <!-- Partie de contact -->
                                <tr v-show="worker.contact.show == true" class="disable-hover border" :ref="'head_worker_' + index">
                                    <td colspan="6" class="p-3">
                                        <div class="row mt-1">
                                            <div class="col-md-6">
                                                <div class="btn btn-dark switch-field p-3 d-flex flex-row justify-content-center align-items-center" v-if="worker.contact.timer === null" @click="startTimer(index)">
                                                    <i class="fas fa-phone-volume mr-2"></i> Commencer l'appel <i class="fas fa-play-circle ml-2" aria-hidden="true"></i>
                                                </div>
                                                <div class="btn btn-outline-dark switch-field p-3 d-flex flex-row justify-content-center align-items-center" v-else-if="worker.contact.timer != null && worker.contact.pause === true && worker.contact.minutes < time_max " @click="restartTimer(index)">
                                                    <i class="fas fa-phone-volume mr-2"></i> Reprendre l'appel <i class="fas fa-play-circle ml-2" aria-hidden="true"></i>
                                                </div>
                                                <div class="btn btn-danger switch-field p-3 d-flex flex-row justify-content-center" v-else-if="worker.contact.timer != null && worker.contact.pause === true && worker.contact.minutes === time_max ">
                                                    Délai dépassé
                                                </div>
                                                <div class="btn btn-dark switch-field p-3 d-flex flex-row justify-content-center align-items-center" v-else @click="pauseTimer(index)">
                                                    <i class="fas fa-phone-volume mr-2"></i> Appel lancé <i class="fas fa-pause-circle ml-2"></i>
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="p-3 border text-center mb-2">
                                                    <!-- ajout de classe text-primary quand le timer roule-->
                                                    <span class="px-3 text-black">
                                                        <i class="fas fa-stopwatch mr-2 mt-2" aria-hidden="true"></i>
                                                        {{ worker.contact.minutes }}
                                                        min
                                                        {{ worker.contact.seconds }}
                                                        sec
                                                        <!-- {{worker.contact}} -->
                                                    </span> <span class="border-left px-3">
                                                        <i class="far fa-calendar mt-2 mr-2" aria-hidden="true"></i> {{ date }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="p-4">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="mb-3" v-if="worker.calls !== undefined && worker.calls.status == 'refused'">
                                                        <label for="">Nombre d'heures supplémentaires refusées :</label>
                                                        <input type="number" class="form-control" v-model="worker.calls.hours_over_refused">
                                                    </div>
                                                    <h4 class="pb-3 mb-4 ">Informations de contact</h4>

                                                    <p class="mb-2">Numéro(s) de téléphone :</p>
                                                    <p v-for="(phone, index) in worker.phones" :key="index" class="mb-2">
                                                        <strong>{{phone}}</strong>
                                                    </p>

                                                    <p class="mb-2">Note :</p>
                                                    <p>
                                                        <strong>
                                                            {{worker.note}}
                                                        </strong>
                                                    </p>
                                                </div>
                                                <div class="col-md-6 custom-radio-answers">
                                                    <div class="mb-3" v-if="worker.calls !== undefined && worker.calls.status == 'refused'">
                                                        <label for="">Nombre d'heures doubles refusées :</label>
                                                        <input type="number" class="form-control" v-model="worker.calls.hours_double_refused">
                                                    </div>
                                                    <h4 class="pb-3 mb-4">Status</h4>

                                                    <div class="d-flex flex-wrap">
                                                   		<label :for="'radio-accepted'+worker.id" class="p-3 w-50 mb-0" :class="worker.contact.status === 'accepted' ? 'bg-secondary text-white' : 'border'" role="button">
                                                            Accepté
                                                   		    <input class="ml-2" type="radio" :name="'status'+worker.id" value="accepted" v-model="worker.contact.status" :id="'radio-accepted'+worker.id">
                                                   		</label>
                                                   		<label :for="'radio-refused'+worker.id" class="p-3 w-50 mb-0" :class="worker.contact.status === 'refused' ? 'bg-secondary text-white' : 'border'" role="button">
                                                   		    Refusé
                                                   		    <input class="ml-2" type="radio" :name="'status'+worker.id" value="refused" v-model="worker.contact.status" :id="'radio-refused'+worker.id">
                                                   		</label>
                                                   		<label :for="'radio-noanswer'+worker.id" class="p-3 w-50 mb-0" :class="worker.contact.status === 'no_answer' ? 'bg-secondary text-white' : 'border'" role="button">
                                                   		    Ne répond pas
                                                   		    <input class="ml-2" type="radio" :name="'status'+worker.id" value="no_answer" v-model="worker.contact.status" :id="'radio-noanswer'+worker.id">
                                                   		</label>
                                                   		<label :for="'radio-working'+worker.id" class="p-3 w-50 mb-0" :class="worker.contact.status === 'at_work' ? 'bg-secondary text-white' : 'border'" role="button">
                                                   		    Déjà au travail
                                                   		    <input class="ml-2" type="radio" :name="'status'+worker.id" value="at_work" v-model="worker.contact.status" :id="'radio-working'+worker.id">
                                                   		</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-3 mt-3">
                                                <div class="col-md-6">
                                                    <div class="btn btn-dark p-3 d-flex flex-row justify-content-center" @click="saveWorker(index)">
                                                        <i class="fas fa-save mt-2 mr-2"></i> Enregistrer les changements
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="btn btn-outline-secondary p-3 d-flex flex-row justify-content-center" @click="worker.contact.show = false">
                                                        <i class="fas fa-times-circle mt-1 mr-1"></i> Annuler
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        </div>

                        <button @click="post()" class="btn btn-dark mt-3 px-4">
                            Soumettre
                        </button>

                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import Mixin from '../Mixin';
    export default {
        props: {
            worker: '',
            route: String,
            back_route: String,
            csrf: String,
            jobs: Array,
            divisions: Array,
            old_data: '',
	        request: '',
            sort_models: Object,
        },
        mixins: [Mixin],
        data: function() {
            return {
                date: new Date().toISOString().slice(0, 10),
                time_max: 3,
                step_one: true,
                step_one_errors: [],
                name:(this.request == null ? '' : this.request.name),
                start_date: (this.request == null ? new Date().toISOString().slice(0, 10) : this.request.start_date),
                end_date: (this.request == null ? new Date().toISOString().slice(0, 10) : this.request.end_date),
                division_id: (this.request == null ? '' : this.request.division_id),
                jobs_claimed: (this.request == null ? {} : this.request.jobs),
                note: (this.request == null ? '' : this.request.note),
                show_workers: false,
                workers: [],
                is_seniority: (this.request == null ? false : !!this.request.isSeniority),
                status: {
                    accepted: 'Accepté',
                    refused: 'Refusé',
                    no_answer: 'Ne répond pas',
                    at_work: 'Déjà au travail'
                },
                sorting: null
            };
        },
        watch: {
            workers: {
                deep: true,
                handler() {
                    let vm = this;
                    Object.values(this.workers).forEach(function(item, index) {

                        if (item.contact.seconds > 59) {
                            clearInterval(vm.workers[index].contact.timer);
                            vm.workers[index].contact.seconds = 0;
                            vm.workers[index].contact.count   = null;
                            vm.workers[index].contact.minutes += 1;
                            vm.startTimer(index);
                        }

                        if (item.contact.minutes === vm.time_max) {
                            vm.pauseTimer(index);
                        }

                    });
                }
            },
            jobs_claimed: {
                deep: true,
                handler() {
                    Object.keys(this.jobs_claimed).forEach(key => {
                        if (this.jobs_claimed[key] == false) {
                            delete this.jobs_claimed[key];
                        }
                    });
                }
            }
        },
        mounted: function(){

            if(this.request){
                this.step_one = false;
                this.sorting  = this.request.sort_model_id;
                this.getWorkers();
            }
        },
        methods: {
            getLabelById(model, id) {
                let result = '';

                if(model == 'divisions') {
                    result = this.divisions.find(o => o.id === parseInt(id));
                } else if (model == 'jobs') {
                    result = this.jobs.find(o => o.id === parseInt(id));
                }

                return result.label;
            },
            getWorkers() {
                this.stepOneValidation();

                if (this.show_workers) {
                    //check if there is a start_date, default to today
                    if(typeof this.start_date === 'object' && this.start_date !== null) {
                        let today = new Date();
                        let dd = String(today.getDate()).padStart(2, '0');
                        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                        let yyyy = today.getFullYear();
                        today = yyyy + '-' + mm + '-' + dd;
                        this.start_date = today;
                    }
                    //check if there is a end_date, default to start_date
                    if(typeof this.end_date === 'object' && this.end_date !== null) this.end_date = this.start_date;

                    axios.post('/workers/list', {
                        start_date : this.start_date,
                        end_date : this.end_date,
                        division_id : this.division_id,
                        jobs:  this.jobs_claimed,
                        seniority  : this.is_seniority,
                        sorting : this.sorting
                    })
                    .then((response) => {

                        this.workers = response.data;
                       // let vm = this;

                        Object.keys(this.workers).forEach( (item, index) => {

                            this.workers[index].contact = {
                                show: false,
                                status: '',
                                seconds: null,
                                minutes: 0,
                                seconds: 0,
                                timer: null,
                                status: '',
                                count: null,
                                pause: false
                            }

                            if(this.request) {

                                this.workers[index].calls = this.request.calls.find(element => element.worker_id == this.workers[index].id);

                                switch (this.workers[index].calls?.status) {
                                    case 'no_answer':
                                        this.workers[index].calls.textStatus = 'Ne répond pas';
                                        break;
                                    case 'accepted':
                                        this.workers[index].calls.textStatus = 'Accepté';
                                        break;
                                    case 'refused':
                                        this.workers[index].calls.textStatus = 'Refusé';
                                        break;
                                    case 'at_work':
                                        this.workers[index].calls.textStatus = 'Au travail';
                                        break;
                                }
                            }


                            this.workers[index].jobs.sort((a,b) => (b.pivot.is_principal > a.pivot.is_principal) ? 1 : ((a.pivot.is_principal > b.pivot.is_principal) ? -1 : 0))

                            //check if worker is working elsewhere
                            if(this.workers[index].workingPeriod.length ){
                                this.workers[index].contact.status = 'at_work';
                            }

                            //get associated request status
                            if(this.request && this.request.workerCalls[this.workers[index].id] !== undefined && !!this.request.workerCalls[this.workers[index].id].status){
                                this.workers[index].contact.status = this.request.workerCalls[this.workers[index].id].status;
                            }
                        });
                    });
                }
            },
            refreshWorkers() {
                this.step_one_errors = [];
                this.workers = {};
                this.show_workers = false;
                this.step_one = true;

            },
            openWorker(index) {
                this.workers[index].contact.show = true;
            },
            startTimer: function(index) {
                let vm = this;
                vm.workers[index].contact.count = 0, vm.workers[index].contact.timer = setInterval(function() {
                    if (vm.workers[index].contact.pause == false) {
                        vm.workers[index].contact.seconds = (vm.workers[index].contact.count++) + 1;
                    }
                }, 1000);
            },
            pauseTimer(index) {
                this.workers[index].contact.pause = true;
            },
            restartTimer(index) {
                this.workers[index].contact.pause = false;
            },
            saveWorker(index) {
                this.pauseTimer(index);
                this.workers[index].contact.show = false;
            },
            post() {
            let url = (this.request ? '/request/save/'+this.request.id : '/request/store' );
                axios.post(url, {
                    name         : this.name,
                    start_date   : this.start_date,
                    end_date     : this.end_date,
                    division_id  : this.division_id,
                    jobs         : this.jobs_claimed,
                    note         : this.note,
                    workers      : this.workers,
                    isSeniority  : this.is_seniority,
                    sort_model_id: this.sorting
                })
                .then((response) => {
                    window.location = response.data.route;
                });
            },
            stepOneValidation() {
                this.step_one_errors = [];

                if (this.name === '') {
                    let error = 'Le champ nom est requis.';
                    this.step_one_errors.push(error);
                }
                if (this.start_date === '') {
                    let error = 'La date de début est requise.';
                    this.step_one_errors.push(error);
                }
                if (this.end_date === '' || this.end_date == null || this.end_date == undefined) {
                    let error = 'La date de fin est requise.';
                    this.step_one_errors.push(error);
                }

                // if (this.start_date > this.end_date) {
                //     let error = 'Le date de fin ne peut être antérieure à la date de début.';
                //     this.step_one_errors.push(error);
                // }

                if (this.start_date >= Date.now()) {
                    let error = 'Le date de début ne peut être supérieure à la date du jour.';
                    this.step_one_errors.push(error);
                }

                if (this.division_id === '') {
                    let error = 'Le champ division est requis.';
                    this.step_one_errors.push(error);
                }

                if (Object.keys(this.jobs_claimed).length === 0) {
                    let error = 'Vous n\'avez sélectionné aucun poste.';
                    this.step_one_errors.push(error);
                }

                if (this.step_one_errors.length > 0) {
                    this.show_workers = false;
                } else {
                    this.show_workers = true;
                    this.step_one = false;
                }

            },

        }
    };
</script>
