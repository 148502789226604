var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.worker.id
      ? _c("h4", [_vm._v("\n        Modifier l'employé\n    ")])
      : _c("h4", [_vm._v("\n        Créer un employé\n    ")]),
    _vm._v(" "),
    _c("div", { staticClass: "card mt-3" }, [
      _c("div", { staticClass: "card-body" }, [
        _c(
          "form",
          { attrs: { action: _vm.route, method: "POST" } },
          [
            _c("input", {
              attrs: { type: "hidden", name: "_token" },
              domProps: { value: _vm.csrf },
            }),
            _vm._v(" "),
            _vm.worker.id
              ? _c("input", {
                  attrs: { type: "hidden", name: "id" },
                  domProps: { value: _vm.worker.id },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-6 form-group" }, [
                _c("label", { attrs: { for: "last_name" } }, [_vm._v("Nom")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.last_name,
                      expression: "last_name",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", id: "last_name", name: "last_name" },
                  domProps: { value: _vm.last_name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.last_name = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6 form-group" }, [
                _c("label", { attrs: { for: "first_name" } }, [
                  _vm._v("Prénom"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.first_name,
                      expression: "first_name",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", id: "first_name", name: "first_name" },
                  domProps: { value: _vm.first_name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.first_name = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-6 form-group" }, [
                _c("label", { attrs: { for: "email" } }, [_vm._v("Courriel")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.email,
                      expression: "email",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "email", id: "email", name: "email" },
                  domProps: { value: _vm.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.email = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6 form-group" }, [
                _c("label", { attrs: { for: "info_punch_id" } }, [
                  _vm._v("Identifiant Info Punch"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.info_punch_id,
                      expression: "info_punch_id",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    id: "info_punch_id",
                    name: "info_punch_id",
                  },
                  domProps: { value: _vm.info_punch_id },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.info_punch_id = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(_vm.phones, function (item, index) {
              return _c("div", { key: index, staticClass: "row mb-3" }, [
                _c("div", { staticClass: "col" }, [
                  _c("label", { attrs: { for: "phone_" + index } }, [
                    _vm._v("Numéro de téléphone #" + _vm._s(index + 1)),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: item.number,
                        expression: "item.number",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { name: "phones[]", id: "phone_" + index },
                    domProps: { value: item.number },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(item, "number", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col align-self-end" }, [
                  index == 0
                    ? _c(
                        "div",
                        {
                          staticClass: "btn btn-outline-dark w-100",
                          on: {
                            click: function ($event) {
                              return _vm.addPhoneNumber()
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "bx bx-plus-circle",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(
                            "\n                            Ajouter une autre numéro de téléphone\n                        "
                          ),
                        ]
                      )
                    : _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "btn btn-outline-secondary w-100",
                            on: {
                              click: function ($event) {
                                return _vm.deleteRow(index, _vm.phones)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "bx bx-minus-circle",
                              attrs: { "aria-hidden": "true" },
                            }),
                            _vm._v(
                              "\n                                Supprimer le numéro de téléphone\n                            "
                            ),
                          ]
                        ),
                      ]),
                ]),
              ])
            }),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-6 form-group custom-form-check" },
                [
                  _c("label", [
                    _vm._v(
                      "Est-ce que l’employé à un permis de conduire valide?"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "has_driving_licence" },
                    domProps: { value: _vm.has_driving_licence },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "row d-flex m-0 p-0" }, [
                    _c("div", { staticClass: "switch-field col p-0 mr-2" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.has_driving_licence,
                            expression: "has_driving_licence",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          id: "no_driver_licence",
                          value: "no",
                          checked: "checked",
                        },
                        domProps: {
                          value: false,
                          checked: _vm._q(_vm.has_driving_licence, false),
                        },
                        on: {
                          change: function ($event) {
                            _vm.has_driving_licence = false
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "no_driver_licence" } }, [
                        _vm._v("Non"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "switch-field col p-0" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.has_driving_licence,
                            expression: "has_driving_licence",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          id: "has_driver_licence",
                          value: "yes",
                        },
                        domProps: {
                          value: true,
                          checked: _vm._q(_vm.has_driving_licence, true),
                        },
                        on: {
                          change: function ($event) {
                            _vm.has_driving_licence = true
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "has_driver_licence" } }, [
                        _vm._v("Oui"),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.has_driving_licence
                ? _c("div", { staticClass: "col-md-6 form-group" }, [
                    _c("label", { attrs: { for: "driving_licence_number" } }, [
                      _vm._v("Numéro du permis de conduire"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.driving_licence_number,
                          expression: "driving_licence_number",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        name: "driving_licence_number",
                        id: "driving_licence_number",
                      },
                      domProps: { value: _vm.driving_licence_number },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.driving_licence_number = $event.target.value
                        },
                      },
                    }),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-6 form-group" }, [
                _c("label", { attrs: { for: "division_id" } }, [
                  _vm._v("Division"),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.division_id,
                        expression: "division_id",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { name: "division_id", id: "division_id" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.division_id = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.divisions, function (division) {
                    return _c(
                      "option",
                      { key: division.id, domProps: { value: division.id } },
                      [_vm._v(_vm._s(division.label))]
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6 form-group" }, [
                _c("label", { attrs: { for: "job_name" } }, [
                  _vm._v("Fonction au dossier"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.job_name,
                      expression: "job_name",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", name: "job_name", id: "job_name" },
                  domProps: { value: _vm.job_name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.job_name = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-6 form-group" }, [
                _c("label", { attrs: { for: "job" } }, [
                  _vm._v("Emploi(s) principal(aux)"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "form-control",
                    staticStyle: { height: "auto" },
                  },
                  _vm._l(_vm.jobs, function (job, index) {
                    return _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.principal_job,
                            expression: "principal_job",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          name: "principal_job[]",
                          id: "principal_job_" + index,
                        },
                        domProps: {
                          value: job.key,
                          checked: Array.isArray(_vm.principal_job)
                            ? _vm._i(_vm.principal_job, job.key) > -1
                            : _vm.principal_job,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.principal_job,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = job.key,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.principal_job = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.principal_job = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.principal_job = $$c
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        { attrs: { for: "principal_job_" + index } },
                        [_vm._v(" " + _vm._s(job.label) + " ")]
                      ),
                    ])
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6 form-group" }, [
                _c("label", { attrs: { for: "job" } }, [
                  _vm._v("Emploi(s) secondaire(s)"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "form-control",
                    staticStyle: { height: "auto" },
                  },
                  _vm._l(_vm.jobs, function (job, index) {
                    return _c("div", { key: index }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sub_jobs,
                            expression: "sub_jobs",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          name: "sub_jobs[]",
                          id: "sub_jobs_" + index,
                        },
                        domProps: {
                          value: job.key,
                          checked: Array.isArray(_vm.sub_jobs)
                            ? _vm._i(_vm.sub_jobs, job.key) > -1
                            : _vm.sub_jobs,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.sub_jobs,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = job.key,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.sub_jobs = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.sub_jobs = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.sub_jobs = $$c
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "sub_jobs_" + index } }, [
                        _vm._v(" " + _vm._s(job.label) + " "),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row " }, [
              _c(
                "div",
                { staticClass: "col-md-6 form-group custom-form-check" },
                [
                  _c("label", [_vm._v("État de l'employé")]),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "is_active" },
                    domProps: { value: _vm.is_active },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "row d-flex m-0 p-0" }, [
                    _c("div", { staticClass: "switch-field col p-0 mr-2" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.is_active,
                            expression: "is_active",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          id: "radioactif1",
                          name: "is_active",
                          value: "no",
                          checked: "checked",
                        },
                        domProps: {
                          value: true,
                          checked: _vm._q(_vm.is_active, true),
                        },
                        on: {
                          change: function ($event) {
                            _vm.is_active = true
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "radioactif1" } }, [
                        _vm._v("Actif"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "switch-field col p-0" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.is_active,
                            expression: "is_active",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          id: "radioactif2",
                          name: "is_active",
                          value: "yes",
                        },
                        domProps: {
                          value: false,
                          checked: _vm._q(_vm.is_active, false),
                        },
                        on: {
                          change: function ($event) {
                            _vm.is_active = false
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "radioactif2" } }, [
                        _vm._v("Inactif"),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6 form-group" }, [
                _c("label", { attrs: { for: "employment_status" } }, [
                  _vm._v("Status d'employé"),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.employment_status,
                        expression: "employment_status",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      name: "employment_status",
                      id: "employment_status",
                    },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.employment_status = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.employment_status_list, function (status, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: status.key } },
                      [_vm._v(_vm._s(status.label))]
                    )
                  }),
                  0
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-4 form-group" }, [
                _c("label", { attrs: { for: "hiring_date" } }, [
                  _vm._v("Date d’embauche"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.hiring_date,
                      expression: "hiring_date",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "date",
                    name: "hiring_date",
                    id: "hiring_date",
                  },
                  domProps: { value: _vm.hiring_date },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.hiring_date = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-4 form-group" }, [
                _c("label", { attrs: { for: "end_hiring_date" } }, [
                  _vm._v("Date de fin d'embauche"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.end_hiring_date,
                      expression: "end_hiring_date",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "date",
                    name: "end_hiring_date",
                    id: "end_hiring_date",
                  },
                  domProps: { value: _vm.end_hiring_date },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.end_hiring_date = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col-md-4 form-group",
                  attrs: { lang: "en-US" },
                },
                [
                  _c("label", { attrs: { for: "seniority" } }, [
                    _vm._v("Ancienneté"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.seniority,
                        expression: "seniority",
                        modifiers: { number: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "number",
                      step: "0.01",
                      name: "seniority",
                      id: "seniority",
                    },
                    domProps: { value: _vm.seniority },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.seniority = _vm._n($event.target.value)
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-3" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "span",
                  {
                    staticClass: "btn btn-outline-dark",
                    on: {
                      click: function ($event) {
                        return _vm.addInactivityPeriod()
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "bx bx-plus-circle",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(
                      "\n                            Ajouter une période d'inactivité\n                        "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(_vm.inactivity_periods, function (item, index) {
              return _c("div", { staticClass: "row mt-3" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "label",
                    {
                      attrs: { for: "inactivity_periods[" + index + "][name]" },
                    },
                    [
                      _vm._v(
                        "Nom de la période d'inactivitée #" + _vm._s(index + 1)
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: item.name,
                        expression: "item.name",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      required: "",
                      type: "text",
                      name: "inactivity_periods[" + index + "][name]",
                      id: "inactivity_periods[" + index + "][name]",
                    },
                    domProps: { value: item.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(item, "name", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "inactivity_periods[" + index + "][start_date]",
                      },
                    },
                    [_vm._v("Date de début")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: item.start_date,
                        expression: "item.start_date",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      required: "",
                      type: "date",
                      name: "inactivity_periods[" + index + "][start_date]",
                      id: "inactivity_periods[" + index + "][start_date]",
                    },
                    domProps: { value: item.start_date },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(item, "start_date", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "inactivity_periods[" + index + "][end_date]",
                      },
                    },
                    [_vm._v("Date de retour")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: item.end_date,
                        expression: "item.end_date",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      required: "",
                      type: "date",
                      name: "inactivity_periods[" + index + "][end_date]",
                      id: "inactivity_periods[" + index + "][end_date]",
                    },
                    domProps: { value: item.end_date },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(item, "end_date", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col align-self-end" }, [
                  _c(
                    "div",
                    {
                      staticClass: "btn btn-outline-secondary w-100",
                      on: {
                        click: function ($event) {
                          return _vm.deleteRow(index, _vm.inactivity_periods)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "bx bx-minus-circle",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(
                        "\n                            Supprimer\n                        "
                      ),
                    ]
                  ),
                ]),
              ])
            }),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-3" }, [
              _c("div", { staticClass: "col" }, [
                _vm._m(1),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.note,
                      expression: "note",
                    },
                  ],
                  staticClass: "form-control custom-textarea",
                  attrs: { name: "note", id: "note" },
                  domProps: { value: _vm.note },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.note = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-dark mt-3 px-4",
                attrs: { type: "submit" },
              },
              [_vm._v("\n                    Soumettre\n                ")]
            ),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "mt-3" }, [
      _c("i", {
        staticClass: "fas fa-info-circle",
        attrs: { "aria-hidden": "true" },
      }),
      _vm._v(
        " Précisez les dates de vacances de l'employé (Ou autre période d’inactivité prévue)\n                "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "note" } }, [
      _vm._v(" Ajouter des notes à l'employé "),
      _c("span", { staticClass: "text-muted" }, [_vm._v("(Falcultatif)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }